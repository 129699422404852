@import url('https://fonts.googleapis.com/css2?family=Poppins:wght@100;600&display=swap');

*{
    margin: 0;
    padding: 0;
    
}
.hd{
    visibility: hidden;
    position: absolute;
}
.lg{
    visibility: visible;

} 
.NAV{

    position: relative;
    width: 100%;
    height: 85px;
    z-index: 1000;
    overflow: hidden;
}
.Navbar a{
    color: white;
    text-decoration: none;
}
.Navbar{
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 85px;
    overflow: hidden;
    z-index: 1000;
    font-family: Poppins;
}
.navbar{
    z-index: 1000;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    background-color: #082032;
  
}
.active{
    color: red;
}
.navbars{
    width: 100%;
    height: 70px;
    background-color: #082032;
    z-index: 1000;
}
.navbar h3{

    color: white;
}
.navbar .btn{
    margin-left: 20px;
}
.navbar .btn h3{
    transform: scale(1);
    transition: 0.7s;
    font-weight: 700;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    border-radius: 50%;
    font-size: 30px;
    width: 55px;
    height: 55px;
    border: 2px solid white;
}
.navbar  a{
    transform: scale(1);
    transition: 0.7s;
    font-weight: 700;
   
}
.navbar .btn h3:hover{
    transform: scale(1.03);
   background-color: rebeccapurple;
}
.Navbar .navbars{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Navbar .navbars .list{
    position: relative;
    display: flex;
    font-size: 17px;
    overflow: hidden;
    height: auto;
}
.Navbar .navbars .list h3{
    cursor: pointer;
    transform: scale(1);
    transition: 0.7s;
}
.Navbar .navbars .list h3:hover{
    color: red;
    transform: scale(1.03);
}
.Navbar .navbars .list a:hover{
    color: red;
    transform: scale(1.03);
}
.Navbar .navbars h3{
    margin: 10px;
}

/* ////////////////media/////////////// */

@media only screen and (max-width: 800px){
    .Navbar .navbars h3{
        font-size: 17px;
    }
    .navbar .btn h3{
        width: 50px;
        height: 50px;
        font-size: 25px;
    }
}
@media only screen and (max-width: 600px){
    .NavbarX .navbars .list{
        position: absolute;
        flex-direction: column;
        display: flex;
        width: 100%;
        align-items: center;
        margin-top: 320px;
        background-color: #082032;
    }
    .Navbar .navbars .list{
        position: absolute;
        flex-direction: column;
        margin-top: 100px;
        width: 100%;
        align-items: center;
        margin-top: 320px;
        background-color: #082032;
       
    }
    .Navbar .navbars .list h3{
        top: 5px;
        position: relative;
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.5px solid white;
        margin: -1px;
    }
    .Navbar .navbars .list h3:hover{
        background-color: #0a2a46;
    }
    .navbar .btn{
        width: 100%;
        justify-content: end;
        display: flex;
        margin-bottom: 150px;
        margin-right: 50px;
        align-items: center;
        transition: 0.7s;
    }
    .navbar .btn h3{
        border-radius: 50%;
        width: 45px;
        height: 45px;
    }
    .lg{
        visibility: visible;
        position: relative;
        right: 110%;
        top: 65px;
        
    }   
    .hd{
        visibility: visible;  
    }

   
}
