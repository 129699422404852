.service{
    margin-top: 50px;
    width: 100%;
    height: auto;
    background-color: aliceblue;
    display: flex;
    overflow: hidden;
    justify-content: center;
}

.service .services h3{
    display: flex;
    margin-top: 10px;
    font-weight: 500;
    color: rgb(90, 9, 9);
    justify-content: center;
}
.service .services span{
    display: flex;
    font-size: 40px;
    font-weight: 600;
    margin-top: 10px;
    justify-content: center;
    margin: 20px;
}
.service .lss{
    transition: 0.6s;
    transform: scale(1);
    transition: 0.7s;
    transform: scale(1);
    overflow: hidden;
    padding: 10px;
    width: 250px;
    height: 250px;
    position: relative;
    cursor: pointer;
    margin: 10px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 4px solid hsl(228, 100%, 90%);
    background-color:hsl(228, 100%, 92%);
}
.service .lss:hover{
    transform: scale(1.04);
}
.service .lss h1{

    display: flex;
    justify-content: center;
    font-size: 40px;
    color: #082032 ;
}
.service .lss h3{
    margin: 10px;
    font-weight: 700;
}
.service .Ser{
    display: flex;
    align-items: center;
}
.service .bt{
    cursor: pointer;
    width: 50px;
    height: 50px;
    transform: scale(1);
    display: flex;
    justify-content: center;
    align-items: center;
   transition: 0.7s;
    color: black;
}
.service .bt:hover{
    transform: scale(1.04);
}
.service .main::-moz-progress-bar{
    display: none;
}
.service .main::-ms-o
.service .main::-webkit-progress-bar{
    display: none;
}
.service .main::-webkit-scrollbar{
    display: none;
}
.service .main{
    display: flex;
    width: 100%;
    overflow: hidden;
    overflow-x:scroll ;
    align-items: center;
    
}
.service .Serr{
   
}
.service .Services{
    display: flex;
    width: 900px;
    
}



/* //////////mobile////////////// */
@media only screen and (max-width: 1600px){
    .service .Services{
        width: 1100px;
        
    }
    .service .lss{
      width: 280px;
      height: 250px;
    }
}
@media only screen and (max-width: 1188px){
    .service .Services{
        width: 760px;
        
    }
    .service .lss{
      width: 230px;
      height: 230px;
    }
}
@media only screen and (max-width: 1000px){
    .service .Services{
        width: 700px;
        
    }
    .service .lss{
      width: 220px;
      height: 210px;
    }
}
@media only screen and (max-width: 885px){
    .service .Services{
        width: 300px;
        
    }
    .service .lss{
      width: 262px;
      height: 250px;
    }
}
@media only screen and (max-width: 438px){
    .service .Services{
        width: 280px;
        
    }
    .service .lss{
      width: 250px;
      height: 220px;
    }
}