@import url(https://fonts.googleapis.com/css2?family=Poppins:wght@100;600&display=swap);
@import url(https://fonts.googleapis.com/css2?family=Source+Sans+Pro:ital,wght@0,200;0,400;1,400&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

body{
    background-color: aliceblue;
}
.bodyy{
    background-color: aliceblue;
    width: 100%;
    height: auto;
    overflow: hidden;

}
*{
    margin: 0;
    padding: 0;
}
.About{
    overflow: hidden;
    width: 100%;
    height: auto;
    font-weight: 600;
}

.About .about h3{
    display: flex;
    margin-top: 10px;
    font-weight: 500;
    color: rgb(90, 9, 9);
    justify-content: center;
}
.About .about span{
    display: flex;
    font-size: 40px;
    font-weight: 600;
    margin-top: 10px;
    justify-content: center;
    margin: 20px;
}
.abouts{
    margin-top: 50px;
    display: flex;
    justify-content: center;
    width: 100%;
    height: auto;
}
.abouts .list{
    flex-wrap: nowrap;
    overflow: hidden;
    width: 90%;
    height: auto;
   /* border: 2px solid black; */
}
.abouts .mr{
    visibility: hidden;
}
.about .list .imgs{
  display: flex;
  align-items: center;
  justify-content: center;
}
.about .list img:hover{
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
}
.about .list img{
    transition: 0.7s;
    cursor: pointer;
    overflow: hidden;
    border-radius: 20px;
    border-top-right-radius: 50%;
    border-top-left-radius:50% ;
    width: 400px;
    height: 400px;
    margin-bottom: 100px;
    background-color: white;
    /* background-color: hsl(208, 100%, 95%); */
    border: 2px solid gray;
}
.about .list{
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
}


.about .list .det{
   display: flex;
   align-items: center;
   width: 100%;
   justify-content: center;
 
}
.about .list .det .Text{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
}
.about .list .det .text{
    width: 70%;
    display: flex;
    align-items: center;
    overflow: hidden;
}
.about .list .more{
    visibility: hidden;
}
.about .list .det .btn{
    display: flex;
}
.about .list .det .btn button:hover{
    background-color: transparent;
    color: black;
    border: 1px solid black;
}
/* .about .dtt{
    cursor: none;
} */
.about .list .det .btn button{
    cursor: pointer;
    background-color: #082032;
    color: white;
    border: none;
    transition: 0.7s;
    margin: 20px;
    width: 130px;
    height: 45px;
    font-size: 15px;
    border-radius: 20px;
}
.about .list p{
    text-align: justify;
   
}
@media only screen and (max-width: 1397px){
    .abouts .mr input{
        width: 100px;
        cursor: pointer;
        height: 30px;
        background-color: aliceblue;
        color: black;
    }
    .about .list p{
        width: 100%;
        font-size: 18px;
        margin-top: 20px;
    }
    .about .list .det .text {
        width: 90%;
    }
    .abouts .text{
        margin-top: 20px;
        width: 100%;
        top: 30px;
    }
    .abouts .mr{
        visibility: visible;
           
    }
    .about .list p{
        font-size:18px;
        display: -webkit-box;
        -webkit-line-clamp: 30;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
}
@media only screen and (max-width: 1350px){
    .about .list p{
        font-size: 18px;
    }
    .abouts .text{
        margin-top: 20px;
    }
}
@media only screen and (max-width: 1248px){
    .about .list p{
        margin-top: 20px;
    }
    .about .list p{
        font-size: 16px;
    }
    .about .list .det .btn button{
        width: 130px;
        height: 45px;
        font-size: 15px;
    }
}
@media only screen and (max-width: 1204px){
    .about .list p{
        font-size: 18px;
    }
    .about .list .det .btn button{
       
        margin: 8px;
        width: 130px;
        height: 45px;
        font-size: 15px;
    }
}
@media only screen and (max-width: 1145px){
    .about .list p{
        font-size: 18px;
    }
}
@media only screen and (max-width: 1102px){
    .about .list p{
        font-size: 18px;
    }
}
@media only screen and (max-width: 1040px){
    .abouts .mr{
        visibility: visible;
           
    }
    .about .list p{
        font-size: 18px;
        display: -webkit-box;
        -webkit-line-clamp: 30;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }
    .about .list .det .btn button{
        width: 130px;
       height: 45px;
       font-size: 15px;
        margin: 6px;
      
    }
    .abouts .mr input{
        width: 100px;
        cursor: pointer;
        height: 30px;
        background-color: aliceblue;
        color: black;
    }
}


@media only screen and (max-width: 978px){
    .about .list img{
      margin-bottom: 0;
    }
    .about .list img{
        width: 100%;
        height: 800px;
     
    }
    .about .list .det .text {
        width: 95%;
    }
    
    .abouts .mr{
        visibility: visible;
           
    }
    .abouts .mr input{
        margin-top: 20px;
        width: 100px;
        cursor: pointer;
        height: 30px;
        background-color: aliceblue;
        color: black;
    }
    .about .list .imgs{
        margin-top: 10px;
    }
    .about .list .det{
        margin-top: 0px;
    }
    .about .list .imgs{
        width: 90%;
        display: flex;
        justify-content: center;
    }
    .about .list{
        flex-wrap: wrap;
        justify-content: center
    }
    .about .list p{
        font-size: 25px;
        overflow: hidden;
    }
    .about .list .det .btn button{
        width: 150px;
        height: 50px;
        font-size: 17px;
        margin: 10px;
  
    }
 
}@media only screen and (max-width: 830px){
    .about .list img{
        width: 100%;
        height: 700px;
     
    }
    
    
 
}

@media only screen and (max-width: 750px){
    .about .list img{
        width: 100%;
        height: 600px;
     
    }
    
    .abouts .list{
        width: 98%;
     
       /* border: 2px solid black; */
    }
    .about .list .det .text{
        justify-content: center;
        width: 95%;
    }
 
}

@media only screen and (max-width: 633px){
    .abouts .list{
        width: 98%;
     
       /* border: 2px solid black; */
    }
    .about .list .more{
        visibility: visible;
    }
}
@media only screen and (max-width: 600px){
    .abouts .list{
        width: 98%;
    }
    .about .list img{
        width: 90%;
        height: 500px;
     
    }
    .about .list p{
        width:90%;
      
    }

   
    .about .list .det .text{
        width: 100%;
        
     
    }
    /* .about .mores{
        background-color: brown;
        width: 100%;
        height: 40px;
      
    } */

    .about .list .det .btn button{
        width: 130px;
        height: 45px;
        font-size: 15px;
        margin: 5px;
        
    }
  
  .about .list .more{
    visibility: visible;
}
   .list .more{
    cursor: pointer;
    color: #082032;
    visibility: visible;
    
   }


}

@media only screen and (max-width: 448px){
    .about .list img{
        width: 90%;
        height: 450px;
     
    }
    .abouts .list{
        width: 98%;
     
       /* border: 2px solid black; */
    }
    .about .list .det .btn button{
        width: 130px;
        height: 45px;
        font-size: 15px;
        margin: 10px;
      
    }
    .about .list .imgs{
        margin-top: 10px;
    }
 
 
  
}
@media only screen and (max-width: 400px){
    .about .list .det .text{
        width: 90%;
    }
    .abouts .list{
        width: 100%;
     
       /* border: 2px solid black; */
    }
    .about .list .imgs{
        margin-top: 7px;
    }
    
    .about .list .det p{
        font-size: 18px;
        width: 700px;
     
        
    }
    .about .list img{
        width: 300px;
        height: 301px;

    }
    .about .list .det .btn button{
        width: 130px;
        height: 45px;
        font-size: 17px;
        margin: 5px;
     
    }
}

/* 








/* 
.about .Text button:hover{
    background-color: transparent;
    color: black;
    border: 2px solid black;
}
.about .Text button{
    width: 140px;
    height: 50px;
    font-size: 18px;
    cursor: pointer;
    background-color: #082032;
    color: gainsboro;
    font-weight: 600;
    display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.7s;
  border: none;
  border: 1px solid white;
  margin: 10px;
 

}
.about .hide h1{
    display: flex;
    justify-content: center;
    cursor: pointer;
    font-size: 50px;
    color: #082032;
}
.about .list .det p{
    width: 500px;
    height: auto;
    font-size: 16px;
    font-weight: 600;
}
.about .list .det .btn{
    display: flex;
    justify-content: center
} */

*{
    margin: 0;
    padding: 0;
}
.Dt{
    width: 100%;
    height: auto;
    font-weight: 600;
}
.Dt .list{
    height: auto;
   width: 100%;
   display: flex;
   justify-content: center;
   margin-top: 20px;
   
}
.Dt .lists{
    display: flex;
    flex-wrap: nowrap;
    border-radius: 20px;
    border: 2px solid black;
    width: 90%;
    background-color:aliceblue;
    justify-content: center;
    justify-content: space-around;
}
.Dt .lists .ff{
      width: 100%;
      display: flex;
     flex-wrap: wrap;
     justify-content: center;
     align-items: center;
     
}

.Dt .lists .ff .nx{
    width: 40%;
    display: flex;
    align-items: center;
}
.Dt .lists .ss{
    display: flex;
    flex-direction: column;
}
.Dt .lists span{
    font-size: 20px;
    margin: 7px;
}
.Dt .lists .nm{
    font-size: 20px;
    font-weight: 600;
}

@media only screen and (max-width: 1060px){
    .Dt .lists{
        width: 90%;

    }
    .Dt .lists span{
        font-size: 20px;
        margin: 7px;
    }
    .Dt .lists .ff .nx{
        width: 45%;
      
    }
    .Dt .lists .nx{
        font-size: 20px;
        font-weight: 600;
    }
}

@media only screen and (max-width: 900px){
    
    .Dt .lists .ff{
        width: 100%;
       justify-content: center;
    }
    .Dt .lists .ff .nx {
        width: 80%;
    }
    .Dt .lists{
       flex-wrap: wrap;
    }
    .Dt .lists .ff span{
        font-size: 35px;
    }
    .Dt .lists .ff{
        
        display: flex;
        flex-direction: column;
    }
    .Dt .lists .ss{
        display: flex;
        flex-direction: column;
    }
    .Dt .lists .ss{
        margin-left: 20px;
    }
}

@media only screen and (max-width: 800px){
    
    .Dt .lists .ff{
        width: 100%;
       
    }
    .Dt .lists .ff .nx {
        width: 90%;
    }
    .Dt .lists{
       flex-wrap: wrap;
    }
    .Dt .lists .ff span{
        font-size: 35px;
    }
    .Dt .lists .ff{
        
        display: flex;
        flex-direction: column;
    }
    .Dt .lists .ss{
        display: flex;
        flex-direction: column;
    }
    .Dt .lists .ss{
        margin-left: 20px;
    }
}

@media only screen and (max-width: 687px){
   
    .Dt .lists{
        justify-content: flex-start;
        border: none;
        width: 98%;
        box-shadow: 0 0 5px 1px rgb(120, 206, 209);
    }
    .Dt .lists{
       flex-wrap: nowrap;
    }
    .Dt .lists .ff span{
        font-size: 30px;
    }
    .Dt .lists .ss{
        margin-right: 20px;
    }
 
}
@media only screen and (max-width: 614px){
    .Dt .lists{
        justify-content: flex-start;
        border: none;
        box-shadow: 0 0 5px 1px rgb(120, 206, 209);
    }
    .Dt .lists{
       flex-wrap: wrap;
    }
    .Dt .lists .ff .nx{
        width: 100%;
    }
    .Dt .lists .ss{
        position: relative;
        right: 20px;
    }
    .Dt .lists .ff span {
        font-size: 25px;
    }
 
}
@media only screen and (max-width: 505px){
    .about .list img {
        width: 97%;
    }
    .Dt .lists {
        width: 95%;
    }
  
    .Dt .lists .ff{
        height: auto;
    }
    

}
@media only screen and (max-width: 460px){
    .Dt .lists{
        justify-content: flex-start;
        border: none;
        width: 98%;
        box-shadow: 0 0 5px 1px rgb(120, 206, 209);
    }
    .Dt .lists .ff span {
        font-size: 25px;
    }
   
}
@media only screen and (max-width: 417px){
    .about .list img {
        width: 100%;
        height: 450px;
    }
    .Dt .lists .ff span {
        font-size: 20px;
    }
}
@media only screen and (max-width: 370px){
    .Dt .lists span{
        font-size: 17px;
        font-weight: 600;
    };
  
}
  
*{
    margin: 0;
    padding: 0;
}
.con{
    width: 100%;
    overflow: hidden;
    height: auto;
    background-color:aliceblue ;
}
.contact{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
    background-color: aliceblue;
}
.contact .contacts{
    
    width: 90%;
    height: auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: aliceblue;
}

.contact .contacts h3{
    display: flex;
    margin-top: 10px;
    font-weight: 500;
    color: rgb(90, 9, 9);
    justify-content: center;
}
.contact .contacts span{
    display: flex;
    font-size: 40px;
    font-weight: 600;
    margin-top: 10px;
    justify-content: center;
    margin: 20px;
}
.contact .contactt .Btn{
   width: 100%;
   display: flex;
   margin-top: 10px;

   justify-content: end;
}
.contact .contactt .btn{
    font-size: 20px;
    cursor: pointer;
    width: 100px;
    height: 50px;
    background-color: #082032;
    color:white;
    transition: 0.7s;
}
.contact .contactt .btn1{
    font-size: 20px;
    cursor: pointer;
    width: 100px;
    height: 50px;
    background-color: #082032;
    color:white;
    transition: 0.7s;
}

.cm{
    background-color:  #082032;
    color: white;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.cm1{
    background-color:rgb(126, 3, 3);
    color: white;
    margin-top: 20px;
    margin-bottom: 10px;
    width: 100%;
    height: 50px;
    display: flex;
    justify-content: center;
    align-items: center;
}
.contact .contactt{
    margin-top: 50px;
    width: 100%;
    background-color:aliceblue;
    display: flex;
    justify-content: center;

}
.contact .Contact{
    flex-wrap: nowrap;
    width: 100%;
    display: flex;
    
}
.contac .Btn{
   transition: 0.7s;
}

.contact .Contact form input{
    background-color: transparent;
    width: 100%;
    height: 50px;
    outline: none;
    margin-top: 5px;
    border: 1.5px solid #082032;
}
.contact .Contact form .email{
    height: 50px;
    font-size: 14px;
    
}
.contact .Contact form textarea{
    background-color: transparent;
    border: 1.5px solid #082032;
    height: 150px;
    font-size: 14px;
    width: 100%;;
    display: flex;
    outline: none;
    margin-top: 5px;
}

.contact .Contact form{
    justify-content: center;
    display: flex;
    width: 90%;
    flex-direction: column;
    margin: 20px;
}
.contact .Contact .box{
    width: 100%;
}
.contact .Contact .Share{
    width: 100%;
    display: flex;
    align-items: center;
    height: 80%;
    justify-content: center;
  
}
.contact .Contact .share{
    justify-content: center;
    display: flex;
  
    align-items: center;
    
}
.contact .Contact .share h1{
    width: 50px;
    margin: 10px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    height: 50px;
    border: 1px solid black;
}
@media only screen and (max-width: 900px){
    .contact .contacts{
        width: 80%;

    }
   
    .contact .Contact form{
        width: 100%;
        justify-content: flex-start;
        position: relative;
       
    }
    .contact .Contact form input{
        background-color: transparent;
        width: 100%;
        margin-top: 5px;
       
    }
    .contact .Contact form textarea{
        width: 100%;
    }
    .contact .Contact form{
        width: 97%; 
    }
    .contact .Contact{
        flex-direction: column;
    }
    .contact .Contact .share{
        justify-content: center;
        align-items: center;
        /* margin-left: 20%; */
    }
    .contact .Contact .share h1{

        width: 40px;
        margin: 10px;
        height: 40px;
        border: 1px solid black;
    }
   

}
@media only screen and (max-width: 950px){
    
    .contact .Contact .share h1{
        width: 40px;
        margin: 10px;
        height: 40px;
        border: 1px solid black;
    }
   
  

    
}
@media only screen and (max-width: 600px){
    .contact .contacts {
        width: 97%;
        margin-right: 10%;
    }
}


.exp{
    margin-top: 50px;
    width: 100%;
    height: auto;
    background-color: aliceblue;
    display: flex;
    justify-content: center;
    overflow: hidden;
}

 .byy{
    width: 30px;
    height: 30px;
    cursor: pointer;
}
.exp .exps{
    width: 100%;
    height: auto;
    background-color: aliceblue;
    display: flex;
    justify-content: center;
    flex-direction: column;
   
}
.exp .exps h3{
    display: flex;
    margin-top: 10px;
    font-weight: 500;
    color: rgb(90, 9, 9);
    justify-content: center;
}
.exp .exps span{
    display: flex;
    font-size: 40px;
    font-weight: 600;
    margin-top: 10px;
    justify-content: center;
    margin: 20px;
}

.exp .Exps{
    width: 100%;
    display: flex;
    justify-content: space-between;
    margin-top: 50px;
    flex-wrap: wrap;
}

.exp .html{
    width: 80%;
    height: 10px;
    background-color: gainsboro;
    border-radius: 20px;
    display: flex;
    align-items: center;
    
}

.exp .f1{
    width: 40%;
    margin: 10px;
    height: 100px;
    color: #082032;
}
.exp .f1 h2{
    font-size: 25px;
    margin-top: 20px;
    margin-bottom: 20px;

}
.exlist{
    width: 100%;
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
}

.exp .html .injv{
    border-radius: 20px;
    width: 90%;
    /* width: 0%; */
    height: 7px;
    background-color:  #082032;
}



/* /////////////////////mobile/////////////////////// */

@media only screen and (max-width: 800px){
   .exp .f1{
    width: 90%;
   }    
}


@media only screen and (max-width: 897px){
    .exp .exps{
        width: 95%;
        justify-content: center;
    }   
    .exp .Exps{
        width: 100%;
        display: flex;
        justify-content: center;
    }   
    .exlist{
        width: 100%;
        justify-content: center;
    }

}


.portos{
    width: 100%;
    height: auto;
    display: block;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.port {
    margin-top: 50px;
    width: 100%;
    height: auto;
    background-color: aliceblue;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  
  .port .ports {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 70%;
    align-items: center;
    justify-content: center;
    height: auto;
    background-color: aliceblue;
  }
  
  .port .ports h3 {
    display: flex;
    margin-top: 10px;
    font-weight: 500;
    color: rgb(90, 9, 9);
    justify-content: center;
  }
  
  .port .ports span {
    display: flex;
    font-size: 35px;
    font-weight: 600;
    margin-top: 10px;
    justify-content: center;
    margin: 20px;
  }
  
  .port .Ports img{
    width: 400px;
    height: 400px;
    border-radius: 10px;
  }
 .port .byy {
  width: 50px;
  height: auto;
 }
 
  .port .Ports .lport{
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .port .Ports .dit {
    background-color: #082032;
    color: white;
    align-items: center;
    border-radius: 20px;
    overflow-x: hidden;
    width: 95%;
    height: 30px;
    display: flex;
    justify-content: space-between;
  }
  
  .port .Ports .dit .time {
    margin-left: 30px;
  }
  
  .port .Ports .dit .title {
    margin-right: 30px;
  }
  
  .port .Ports .btn {
    width: 94%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .port .Ports {
    margin-top: 50px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  
  .port .Ports button {
    margin: 5px;
    width: 70px;
    height: 30px;
    font-size: 17px;
    background-color: #082032;
    color: white;
    cursor: pointer;
    border: none;
    transition: 0.8s;
  }
  
  .port .Ports button:hover {
    background-color: transparent;
    border: 2px solid #082032;
    color: #082032;
  }
  

  
  @media only screen and (max-width: 1120px) {
    .port .ports {
      width: 100%;
    }
  }
  

  
@media only screen and (max-width: 885px){

    .port .Ports {
        flex-wrap: wrap;
        justify-content: center;
    }
   
    .port .Ports .lport {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .port .Ports .imgxx {
      overflow: hidden;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .port .Ports .dit .time{
       font-size: 20px;
       font-weight: 600;
    }
    .port .Ports .dit .title{
      font-size: 20px;
      font-weight: 600;
    }
    .port .Ports .dit{
      width: 25rem;
      height: 50px;
    }
    .port .Ports .btn {
      
    
    }
    .port .Ports img {
      border-radius: 20px;
      width: 30rem;
      height: 400px;
    }
  
}


@media only screen and (max-width: 600px){
  .port .ports {
    width: 100%;
   
  }
}
@media only screen and (max-width: 500px){
  .port .Ports .imgxx {
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .port .Ports .dit .time{
     font-size: 20px;
     font-weight: 600;
  }
  .port .Ports .dit .title{
    font-size: 20px;
    font-weight: 600;
  }
  .port .Ports .dit{
    width: 90%;
  }
  .port .Ports .btn {
    width: 80%;
  
  }
  .port .Ports img {
    border-radius: 20px;
    width: 100%;
    height: 400px;
  }
}
@media only screen and (max-width: 400px){
 
  .port .Ports .dit .time{
     font-size: 17px;
     font-weight: 600;
  }
  .port .Ports .dit .title{
    font-size: 17px;
    font-weight: 600;
  }
  .port .Ports .dit{
    width: 90%;
    height: 50px;
  }
  .port .Ports .btn {
    width: 90%;
  
  }
 
  .port .Ports img {
    border-radius: 20px;
    width: 95%;
    height: 350px;
  }
}
        
.service{
    margin-top: 50px;
    width: 100%;
    height: auto;
    background-color: aliceblue;
    display: flex;
    overflow: hidden;
    justify-content: center;
}

.service .services h3{
    display: flex;
    margin-top: 10px;
    font-weight: 500;
    color: rgb(90, 9, 9);
    justify-content: center;
}
.service .services span{
    display: flex;
    font-size: 40px;
    font-weight: 600;
    margin-top: 10px;
    justify-content: center;
    margin: 20px;
}
.service .lss{
    transition: 0.6s;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: 0.7s;
    transform: scale(1);
    overflow: hidden;
    padding: 10px;
    width: 250px;
    height: 250px;
    position: relative;
    cursor: pointer;
    margin: 10px;
    border-radius: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    border: 4px solid hsl(228, 100%, 90%);
    background-color:hsl(228, 100%, 92%);
}
.service .lss:hover{
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
}
.service .lss h1{

    display: flex;
    justify-content: center;
    font-size: 40px;
    color: #082032 ;
}
.service .lss h3{
    margin: 10px;
    font-weight: 700;
}
.service .Ser{
    display: flex;
    align-items: center;
}
.service .bt{
    cursor: pointer;
    width: 50px;
    height: 50px;
    -webkit-transform: scale(1);
            transform: scale(1);
    display: flex;
    justify-content: center;
    align-items: center;
   transition: 0.7s;
    color: black;
}
.service .bt:hover{
    -webkit-transform: scale(1.04);
            transform: scale(1.04);
}
.service .main::-moz-progress-bar{
    display: none;
}
.service .main::-ms-o
.service .main::-webkit-progress-bar{
    display: none;
}
.service .main::-webkit-scrollbar{
    display: none;
}
.service .main{
    display: flex;
    width: 100%;
    overflow: hidden;
    overflow-x:scroll ;
    align-items: center;
    
}
.service .Serr{
   
}
.service .Services{
    display: flex;
    width: 900px;
    
}



/* //////////mobile////////////// */
@media only screen and (max-width: 1600px){
    .service .Services{
        width: 1100px;
        
    }
    .service .lss{
      width: 280px;
      height: 250px;
    }
}
@media only screen and (max-width: 1188px){
    .service .Services{
        width: 760px;
        
    }
    .service .lss{
      width: 230px;
      height: 230px;
    }
}
@media only screen and (max-width: 1000px){
    .service .Services{
        width: 700px;
        
    }
    .service .lss{
      width: 220px;
      height: 210px;
    }
}
@media only screen and (max-width: 885px){
    .service .Services{
        width: 300px;
        
    }
    .service .lss{
      width: 262px;
      height: 250px;
    }
}
@media only screen and (max-width: 438px){
    .service .Services{
        width: 280px;
        
    }
    .service .lss{
      width: 250px;
      height: 220px;
    }
}
.footer{
    margin-top: 50px;
    width: 100%;
    height: 250px;
    background-color: #082032;
}
.footer  h3{
    display: flex;
    margin-top: 10px;
    font-weight: 500;
    color: white;
    justify-content: center;
  
}
.footer  span{
    display: flex;
    font-size: 30px;
    font-weight: 600;
    padding-top: 20px;
    color: white;
    justify-content: center;
    margin: 10px;
}
.footer ul{
    display: flex;
    color: wheat;
    align-items: center;
    justify-content: center;
    list-style-type: none;
    font-size: 17px;
    margin-top: 10px;
}
.footer ul li{
    margin: 10px;
    cursor: pointer;
    font-weight: 600;
    transition: 0.7s;
}
.footer ul li:hover{
    color: red;
}
.footer .line{
    color: wheat;
    display: flex;
    justify-content: center;
    
}
.footer .line h1:hover{
    background-color: transparent;
}
.footer .line h1{
    transition: 0.7s;
    font-size: 23px;
    -webkit-transform: scale(1);
            transform: scale(1);
    cursor: pointer;
 
    margin: 10px;
}
.footer a{
    transition: 0.7s;
    -webkit-transform: scale(1);
            transform: scale(1);
    text-decoration: none;
    color: white;
}
.footer ul li a:hover{
    color: red;
}
.footer .line h1:HOVER{
    -webkit-transform: scale(1.09);
            transform: scale(1.09);
}
.footer .copy{
    color: wheat;
    display: flex;
    justify-content: center;
    margin-top: 20px;
}
@media only screen and (max-width: 550px){
   .footer ul li{
    margin: 6px;
   }
}
@media only screen and (max-width: 432px){
    .footer ul li{
     margin: 4px;
    }
 }
 @media only screen and (max-width: 400px){
    .footer ul li{
     font-size: 15px;
    }
 }
*{
    margin: 0;
    padding: 0;
    
}
.hd{
    visibility: hidden;
    position: absolute;
}
.lg{
    visibility: visible;

} 
.NAV{

    position: relative;
    width: 100%;
    height: 85px;
    z-index: 1000;
    overflow: hidden;
}
.Navbar a{
    color: white;
    text-decoration: none;
}
.Navbar{
    overflow: hidden;
    position: absolute;
    width: 100%;
    height: 85px;
    overflow: hidden;
    z-index: 1000;
    font-family: Poppins;
}
.navbar{
    z-index: 1000;
    width: 100%;
    height: 100px;
    display: flex;
    align-items: center;
    background-color: #082032;
  
}
.active{
    color: red;
}
.navbars{
    width: 100%;
    height: 70px;
    background-color: #082032;
    z-index: 1000;
}
.navbar h3{

    color: white;
}
.navbar .btn{
    margin-left: 20px;
}
.navbar .btn h3{
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: 0.7s;
    font-weight: 700;
    display: flex;
    align-items: center;
    cursor: pointer;
    justify-content: center;
    border-radius: 50%;
    font-size: 30px;
    width: 55px;
    height: 55px;
    border: 2px solid white;
}
.navbar  a{
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: 0.7s;
    font-weight: 700;
   
}
.navbar .btn h3:hover{
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
   background-color: rebeccapurple;
}
.Navbar .navbars{
    display: flex;
    align-items: center;
    justify-content: center;
}
.Navbar .navbars .list{
    position: relative;
    display: flex;
    font-size: 17px;
    overflow: hidden;
    height: auto;
}
.Navbar .navbars .list h3{
    cursor: pointer;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: 0.7s;
}
.Navbar .navbars .list h3:hover{
    color: red;
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
}
.Navbar .navbars .list a:hover{
    color: red;
    -webkit-transform: scale(1.03);
            transform: scale(1.03);
}
.Navbar .navbars h3{
    margin: 10px;
}

/* ////////////////media/////////////// */

@media only screen and (max-width: 800px){
    .Navbar .navbars h3{
        font-size: 17px;
    }
    .navbar .btn h3{
        width: 50px;
        height: 50px;
        font-size: 25px;
    }
}
@media only screen and (max-width: 600px){
    .NavbarX .navbars .list{
        position: absolute;
        flex-direction: column;
        display: flex;
        width: 100%;
        align-items: center;
        margin-top: 320px;
        background-color: #082032;
    }
    .Navbar .navbars .list{
        position: absolute;
        flex-direction: column;
        margin-top: 100px;
        width: 100%;
        align-items: center;
        margin-top: 320px;
        background-color: #082032;
       
    }
    .Navbar .navbars .list h3{
        top: 5px;
        position: relative;
        width: 100%;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 0.5px solid white;
        margin: -1px;
    }
    .Navbar .navbars .list h3:hover{
        background-color: #0a2a46;
    }
    .navbar .btn{
        width: 100%;
        justify-content: end;
        display: flex;
        margin-bottom: 150px;
        margin-right: 50px;
        align-items: center;
        transition: 0.7s;
    }
    .navbar .btn h3{
        border-radius: 50%;
        width: 45px;
        height: 45px;
    }
    .lg{
        visibility: visible;
        position: relative;
        right: 110%;
        top: 65px;
        
    }   
    .hd{
        visibility: visible;  
    }

   
}

*{
    margin: 0;
    padding: 0;
    scroll-behavior: smooth;
}

.bg{
    display: flex;
    justify-content: center;
    width: 100%;
    height: 100vh;
    background-size: cover;
    background-position: center;
    overflow: hidden;
}
.bgs{
    width: 100%;
    height: 80vh;
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}


/* .bgs .center{
    width: 400px;
    height: 300px;
    border: 1px solid white;
} */
.bgs .hoo{
  color:#b4db59;
}
.bgs .center .title{
    border: 2px solid wheat;
    width: 700px;
    height: 300px;
    box-shadow:  0 0 20px 0.5px ;
    -webkit-backdrop-filter: blur(7px);
            backdrop-filter: blur(7px);
    margin-top: 13%;
    color: rgb(236, 154, 39);
  
}

.active{
    color: red;
}
.bgs .center{
    width: 100%;
    display: flex;
    justify-content: center;
    
}
.bgs .center .title h3{
    color: red;
    display: flex;
    font-size: 30px;
    justify-content: center;
    color: wheat;
    margin: 10px;

}
.bgs .center .title button:hover{
    background-color: transparent;
    color:wheat ;
}
.bgs .center .title button{
    width: 130px;
    height: 45px;
    font-size: 17px;
    cursor: pointer;
    background-color: #082032;
    color: gainsboro;
    font-weight: 600;
    display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.7s;
  border: none;
  border: 1px solid white;
  margin: 30px 120px;

}
.bgs .center .title .sp h3{
   font-size: 30px;
   font-weight: 600;
}
.bgs .center .title h1 span{
    color: red;
    border-bottom: 2px solid gray;  
}
.bgs .center .title p{
    padding-left: 30px;
}
.bgs .center .title h1{
   display: flex;
   font-weight: 600;
   justify-content: center;
}
.bgs .center .title p{
    font-size: 30px;
    display: flex;
    margin-top: 20px;
}
.bgs .center .title .let{
    width: 100%;
    display: flex;
    justify-content: center;
}


/* ////////////////media/////////////// */

/* @media only screen and (max-width: 800px){
    .bgs .center .title{
        width: 400px;
        height: 250px;
        border: none;
        backdrop-filter: blur(5px);
        margin-top: 13%;
        color: white;
    }
    .bgs .center .title h3{
       font-size: 25px;
    
    }
    .bgs .center .title h6{
        font-size: 25px;
     
     }
     .bgs .center .title button{
        width: 130px;
        height: 45px;
        font-size: 17px;
    }
} */
@media only screen and (max-width: 600px){
    /* .bgs .center{
        width: 200%;
        display: flex;
        justify-content: center;
    }
    */
    .bgs .center .title{
        height: auto;
    }
    .bgs .center .title button{
        width: 150px;
        height: 50px;
        font-size: 20px;
    }
    .bgs .center .title h3{
        font-size: 30px;
        margin: 10px;
    
    }

  
    .bgs .center .title .sp h3{
       font-size: 20px;
       font-weight: 600;
    }
  
    .bgs .center .title h1{
       display: flex;
       font-weight: 600;
       justify-content: center;
    }
    

.bgs .left button{
    margin-left: 20px;
    width: 70px;
    height: 40px;
}

/* .bgs .center .title h3{
    font-size: 17px;
    margin: 10px;

} */
.bgs .right button{
    margin-right: 20px;
    cursor: pointer;
    width: 70px;
    height: 40px;
    background-color: transparent;
  
}
}


@media only screen and (max-width: 500px){
    /* .bgs .center .title{
      margin-right: 20%;
    } */
    /* .bgs .center {
        width: 100%;
        display: flex;
        justify-content: center;
    } */
    
    /* .bgs .left h1{
        font-size: 20px;
       
    } */
    
    /* .bgs .left button{
        margin-left: 20px;
        width: 50px;
        height: 30px;
    }
    .bgs .right h1{
        font-size: 20px;
       
    }
    .bgs .right button{
        margin-right: 20px;
        cursor: pointer;
        width: 50px;
        height: 30px;
    } */
    .bgs .right button{
        cursor: pointer;
    }
}



@media only screen and (max-width: 450px){
    /* .bgs .center .title{
        margin-right: 40%;
      } */
    .bgs .left h1{
        font-size: 18px;
       
    }
    
    /* .bgs .left button{
        margin-left: 20px;
        width: 40px;
        height: 25px;
    }
    .bgs .right h1{
        font-size: 18px;
       
    } */
   
    /* .bgs .center .title h3{
        font-size: 17px;
        margin: 10px;
    
    } */

  
    .bgs .center .title .sp h3{
       font-size: 18px;
       font-weight: 600;
    }
  
    .bgs .center .title h1{
        font-size: 30px;
       font-weight: 600;
       justify-content: center;
    }
    /* .bgs .center .title button{
        width: 130px;
        height: 45px;
        font-size: 17px;
    } */

};
@media only screen and (max-width: 410px){
    /* .bgs .center .title{
        
      } */

      /* .bgs .center .title h3{
        font-size: 15px;
        font-weight: 600;
     } */
     
     .bgs .center .title .sp h3{
        font-size: 17px;
        font-weight: 600;
     }
   
     .bgs .center .title h1{
        display: flex;
        font-weight: 600;
        justify-content: center;
     }
     
    
}
@media only screen and (max-width: 350px){
    /* .bgs .center .title{
        
        margin-right: 60%;
    } */

  
    .bgs .center .title h3{
        font-size: 20px;
        margin: 10px;
    
    }
    .bgs .center .title button{
        width: 140px;
        height: 40px;
        font-size: 18px;
      
    }


}
.loading-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: ghostwhite;
    
  }
  
  .loading-bar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
  }
  
  .loading-bar {
    width: 10px;
    height: 40px;
    margin: 0 5px;
    background-color: #000;
    -webkit-animation: loading-bar-animation 1.2s ease-in-out infinite;
            animation: loading-bar-animation 1.2s ease-in-out infinite;
  }
  
  .loading-bar:nth-child(1) {
    -webkit-animation-delay: 0s;
            animation-delay: 0s;
  }
  
  .loading-bar:nth-child(2) {
    -webkit-animation-delay: 0.1s;
            animation-delay: 0.1s;
  }
  
  .loading-bar:nth-child(3) {
    -webkit-animation-delay: 0.2s;
            animation-delay: 0.2s;
  }
  
  .loading-bar:nth-child(4) {
    -webkit-animation-delay: 0.3s;
            animation-delay: 0.3s;
  }
  
  .loading-bar:nth-child(5) {
    -webkit-animation-delay: 0.4s;
            animation-delay: 0.4s;
  }
  
  .loading-bar:nth-child(6) {
    -webkit-animation-delay: 0.5s;
            animation-delay: 0.5s;
  }
  
  @-webkit-keyframes loading-bar-animation {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.4);
              transform: scale(0.4);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  @keyframes loading-bar-animation {
    0% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
    50% {
      -webkit-transform: scale(0.4);
              transform: scale(0.4);
    }
    100% {
      -webkit-transform: scale(1);
              transform: scale(1);
    }
  }
  
  /* Responsive design using media queries */
  @media screen and (max-width: 768px) {
    .loading-bar-container {
      height: 40px;
    }
    .loading-bar {
      width: 8px;
      height: 30px;
      margin: 0 4px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .loading-bar-container {
      height: 30px;
    }
    .loading-bar {
      width: 6px;
      height: 20px;
      margin: 0 3px;
    }
  }
  
.not-found-container {
    margin-top: 150px;
    width: 100%;
    overflow: hidden;
    display: flex;
    align-items: center;
    flex-direction: column;
    text-align: center;
    align-items: center;
  }
  
  .not-found-heading {
    width: 50%;
    height: 10%;
    font-size: 24px;
    margin-bottom: 20px;
  }
  
  .not-found-image {
    width: 300px;
    height: auto;
    margin-bottom: 40px;
  }
  
  /* Media queries for responsiveness */
  
  @media (max-width: 768px) {
    .not-found-heading {
      font-size: 20px;
    }
  
    .not-found-image {
      width: 200px;
    }
  }
  
  @media (max-width: 480px) {
    .not-found-heading {
      font-size: 18px;
    }
  
    .not-found-image {
      width: 150px;
    }
  }
  

.containerz {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.login{
    height: 70vh;
    
}
.login-title {
    text-align: center;
    color: #333;
   
}

.login-form {
    margin-top: 20px;
}

.login-label, .login-input {
    display: block;
    margin-bottom: 10px;
    width: 80%;
}

.login .login-input{
   
}
.login-input[type="text"], .login-input[type="password"] {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-button {
    display: block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #07406e;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.login-success, .login-failure {
    display: none;
    text-align: center;
    margin-top: 10px;
    padding: 10px;
    background-color: #4CAF50; /* Green for success */
    color: white;
    border-radius: 4px;
}

.login-failure {
    background-color: #f44336; /* Red for failure */
}

.sign-up-link {
    text-align: center;
    margin-top: 20px;
}

.sign-up-link a {
    color: #07406e;
    text-decoration: none;
    font-weight: bold;
}

.sign-up-link a:hover {
    text-decoration: underline;
}

.flash-messages{
    position: absolute;
    top: 10%;
}
/* Flash message container */
 /* .flash-messages {
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    text-align: center;
    background: #f44336;
    color: white;
    padding: 10px;
}

/* Flash message list */


*{
    margin: 0;
    padding: 0;
}

.containerx {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
   
}

.post-title {
    text-align: center;
    color: #333;
}

.post-form {
    margin-top: 20px;
}

.post-label, .post-input, .post-textarea, .post-select {
    display: block;
    margin-bottom: 10px;
}

.post-select, .post-input[type="text"], .post-textarea {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.post-select {
    -webkit-appearance: none;
            appearance: none;
}

.numx{
   width: 20%;
   padding: 10px;
   border: 1px solid #ccc;
   border-radius: 4px;
}
.image-preview {
    text-align: center;
    display: none;
}

.post-image {
    max-width: 100%;
    max-height: 200px;
    margin-top: 10px;
}

.post-button {
    display: block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #07406e;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

*{
    margin: 0;
    padding: 0;
    font-family: Source Sans Pro;
    list-style: none;
    box-sizing: border-box;
}
.bodyyc .detail{
    width: 80%;
    height: auto;
    display: flex;
    justify-content: center;
}



.bodyyc .details{
   width: 100%;
   display: flex;
   flex-direction: column;
   height: auto;
  
}


.bodyyc .delete-comment{
    width: 100px;
    background-color: red;
    height: 30px;
}
.bodyyc .detailsi{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: center;
}
.bodyyc .details{ 
    width: 100%;
    height: auto;
    justify-content: center;
    display: flex;

}

.bodyyc .detail #editbtn,#delete,#save ,#close{
    margin: 3px;
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.bodyyc{
    display: flex;
    justify-content: center;
}
.bodyyc .detail #titlebox{
    margin-top: 3%;
    font-size: 22px;
    font-weight: 600;
    color:#031829;;
}
.bodyyc .allbtns{
    width: 100%;
    height: auto;
    display: flex;
    flex-direction:column ;
    height: auto;
}
.bodyyc .detail .boxp{
    margin-top: 3%;
    font-size: 22px;
    font-weight: 600;
    color:#031829;;
}
/* Style for the input field */
.bodyyc #titlebox {
    border: none; /* Initially, hide the border */
    outline: none; /* Remove the focus outline */
    font-size: 16px; /* Adjust the font size as needed */
    padding: 5px; /* Add some padding for spacing */
    width: 90%; /* Expand the input to fill its container */
    transition: border 0.3s; /* Add a smooth transition for the border */
    background-color: transparent; /* Make the input background transparent */
    
}

.imageboxx{
    margin-top: 2%;
   
}


.detail #imagebox{
    margin-top: 10%;
    border: 1px solid black;
    height: 70vh;
    max-width: 680px;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
}
.detail #imageboxc{
    margin-top: 2%;
    /* width: 40rem; */
    height: 70vh;
    max-width: 680px;
    width: 100%;
    object-fit: cover;
    border-radius: 20px;
}


.detail .time{
    font-family: Arial, sans-serif;
    font-weight: 600;
    
}
.detail #description{
    display: none;
}
.right .most{
    display: flex;
    justify-content: center;
    color: #007bff;
    font-size: 30px;
    font-weight: 600;
}
.right{
  
    margin-top: 7%;
    display: flex;
    align-items: center;
    width: 35%;
    display: flex;
    flex-direction: column;
    height: auto;
} 
.right .lines{
    height: 4px;
    width: 100%;
    background-color: #0056b3;
}
.right .rights{
    margin: 15px;
    background-color: rgba(222, 219, 219, 0.2);
    width: 90%;
    height: 350px;
    display: flex;
    flex-direction: column;
    align-items: center;
    border-radius: 20px;
    cursor: pointer;
    -webkit-transform: scale(1);
            transform: scale(1);
    transition: 0.7s;
    box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);

}
.right .rights{
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}
.right .rights:hover{
    -webkit-transform: scale(1.01);
            transform: scale(1.01);
}
.right .tite {
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
}
.detail .descx{
    text-align: justify;
    width: 40rem;
}
.right img{
    width: 19rem;
    height: 17rem;
    border-radius: 20px;
}
.right .tite{
    color: #031829;
    position: relative;
    font-size: 20px;
    width: 90%;
    font-weight: 600;
    
}
/* Style for the hidden file input */
#imagefile {
    display: none;
}
.allbtns{
    display: flex;
    justify-content: space-between;
    /* align-items: center; */
    width: 38rem;
    position: relative;
    /* bottom: 50px; */
   
}
/* Style for the label (icon) that triggers the file input */
#imagefile-label {
    width: 10rem;
    cursor: pointer;
    position: relative;
    /* bottom: 40px; */
    border-radius: 5px;
    display: flex;
    justify-content: end;
   
}

.comment .delete{
    cursor: pointer;
    width: 30px;
    height: 30px;
}


/* Style for the FontAwesome icon */

#upload{
    visibility: hidden;
    width: 30px;
    height: 30px;
    background-color: transparent;
    border: 2px solid black;
    background-color: wheat;
  
}
.detail .git, .live{
    height: 40px;
    width: 80px;
    cursor: pointer;
    background-color: #0056b3;
    color: white;
    font-size: 17px;
    border: none;
    
}
.detail .git,.byy{
    margin: 10px;
}
.detail .btn{
    margin-top: 1%;
    display: flex;
    align-items: center;
    width: 100%;
    

}
.popup-container {
    z-index: 9999;
    visibility: hidden;
    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
}

.allbtns{
    position: relative;
    bottom:3%;
    left: 20px;
   
}
.allbtns img{
    background-color: aliceblue;
}

@media screen and (max-width: 1350px){
    .bodyyc .detail{
        width: 90%;
    }
 
  
}

@media screen and (max-width: 1240px) {
    .flash-messages {
        width: 60%;
    }
    .bodyyc .detail{
        width: 100%;
    }
    
    .details{ 
        width: 95%;
      
    }
    .right{
     width: 100%;
   
    }

}

@media screen and (max-width: 1080px) {
    .bodyyc .detail{
        width: 70%;
        justify-content: center;
    }
    .detail{ 
        width: 95%;
        flex-wrap: wrap;
    
    }
    .desc{
        display: flex;
        flex-direction: column;
        /* align-items: center; */
        
  
    }
    .detail .descx {
        width: 95%;
    }
    .vx{
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
   .rightt{
    display: flex;
   }
}
@media screen and (max-width: 1008px) {
    .imageboxx{
        height: auto;
        
    }
    .detail{ 
        width: 95%;
        flex-wrap: wrap;
        height: auto;
    }
    .details{ 
        justify-content: center;
        height: auto;
        width: 80%;
        height: auto;

    }


}

@media screen and (max-width: 995px) {
    .details{ 
        margin-top: 5%;
    }

    .rightt{
        width: 80%;
        justify-content: center;
        display: flex;
        scroll-behavior: smooth;
        /* overflow-y: scroll; */
    }
    .right .rightt{
        margin: 15px;
        background-color: rgba(222, 219, 219, 0.2);
        width: 70%;
        height: auto;
        flex-wrap: nowrap;
    }
  
    .detail .descx{
        text-align: justify;

    }
    .right img{
        width: 15rem;
        height: 15rem;
        border-radius: 20px;
    }
    .flash-messages {
        width: 80%;
    }
    .right .tite{
        color: #031829;
        position: relative;
        font-size: 20px;
        width: 90%;
        font-weight: 600;
        
    }
}


@media screen and (max-width: 800px) {
    
.detail #imagebox {
    width: 95%;
}
    .right{
        margin-top: 7%;
        width: 100%;
        justify-content: center;
    }
    .bodyyc .detail{
        width: 95%;
        justify-content: center
        
    }
    .details .desc .descx {
        width: 95%;
    }
    .right a{
        width: 100%;
    }
    .rightt{
        width: 90%;
    }
    .rightt .rights{
        width: 90%;
        height: auto;
        margin: 2%;
    }
    .right .rightt{
        margin: 15px;
        background-color: rgba(222, 219, 219, 0.2);
        width: 90%;
        height: auto;
        flex-wrap: wrap;
        
    }
    .right img{
        width: 100%;
        height: 20rem;
        border-radius: 20px;
    }
    .right .tite{
        color: #031829;
        position: relative;
        font-size: 20px;
        width: 90%;
        font-weight: 600;
        
    }
   
}



@media screen and (max-width: 700px) {
    .right {
        width: 100%;
    }
    .right .rightt {
        width: 100%;
    }
    .bodyyc .detail{
        width: 95%;
        justify-content: center;
        }
        .rightt .rights {
            width: 100%;
        }
    }
@media screen and (max-width: 600px) {  
    .bodyyc .detail{
        width: 95%;
        justify-content: center
        }
        .detail #imagebox {
            height: 500px;
        } 
    }
 @media screen and (max-width: 500px) {  
    .detail #imagebox {
        height: 450px;
        background-color: gainsboro;
    }      
}
