.portos{
    width: 100%;
    height: auto;
    display: block;
    align-items: center;
    justify-content: center;
    overflow: hidden;
}
.port {
    margin-top: 50px;
    width: 100%;
    height: auto;
    background-color: aliceblue;
    display: flex;
    justify-content: center;
    overflow: hidden;
  }
  
  .port .ports {
    display: flex;
    flex-direction: column;
    overflow: hidden;
    width: 70%;
    align-items: center;
    justify-content: center;
    height: auto;
    background-color: aliceblue;
  }
  
  .port .ports h3 {
    display: flex;
    margin-top: 10px;
    font-weight: 500;
    color: rgb(90, 9, 9);
    justify-content: center;
  }
  
  .port .ports span {
    display: flex;
    font-size: 35px;
    font-weight: 600;
    margin-top: 10px;
    justify-content: center;
    margin: 20px;
  }
  
  .port .Ports img{
    width: 400px;
    height: 400px;
    border-radius: 10px;
  }
 .port .byy {
  width: 50px;
  height: auto;
 }
 
  .port .Ports .lport{
    margin: 20px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  .port .Ports .dit {
    background-color: #082032;
    color: white;
    align-items: center;
    border-radius: 20px;
    overflow-x: hidden;
    width: 95%;
    height: 30px;
    display: flex;
    justify-content: space-between;
  }
  
  .port .Ports .dit .time {
    margin-left: 30px;
  }
  
  .port .Ports .dit .title {
    margin-right: 30px;
  }
  
  .port .Ports .btn {
    width: 94%;
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .port .Ports {
    margin-top: 50px;
    display: flex;
    flex-wrap: nowrap;
    justify-content: space-between;
  }
  
  .port .Ports button {
    margin: 5px;
    width: 70px;
    height: 30px;
    font-size: 17px;
    background-color: #082032;
    color: white;
    cursor: pointer;
    border: none;
    transition: 0.8s;
  }
  
  .port .Ports button:hover {
    background-color: transparent;
    border: 2px solid #082032;
    color: #082032;
  }
  

  
  @media only screen and (max-width: 1120px) {
    .port .ports {
      width: 100%;
    }
  }
  

  
@media only screen and (max-width: 885px){

    .port .Ports {
        flex-wrap: wrap;
        justify-content: center;
    }
   
    .port .Ports .lport {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
    .port .Ports .imgxx {
      overflow: hidden;
      width: 100%;
      display: flex;
      justify-content: center;
    }
    .port .Ports .dit .time{
       font-size: 20px;
       font-weight: 600;
    }
    .port .Ports .dit .title{
      font-size: 20px;
      font-weight: 600;
    }
    .port .Ports .dit{
      width: 25rem;
      height: 50px;
    }
    .port .Ports .btn {
      
    
    }
    .port .Ports img {
      border-radius: 20px;
      width: 30rem;
      height: 400px;
    }
  
}


@media only screen and (max-width: 600px){
  .port .ports {
    width: 100%;
   
  }
}
@media only screen and (max-width: 500px){
  .port .Ports .imgxx {
    overflow: hidden;
    width: 100%;
    display: flex;
    justify-content: center;
  }
  .port .Ports .dit .time{
     font-size: 20px;
     font-weight: 600;
  }
  .port .Ports .dit .title{
    font-size: 20px;
    font-weight: 600;
  }
  .port .Ports .dit{
    width: 90%;
  }
  .port .Ports .btn {
    width: 80%;
  
  }
  .port .Ports img {
    border-radius: 20px;
    width: 100%;
    height: 400px;
  }
}
@media only screen and (max-width: 400px){
 
  .port .Ports .dit .time{
     font-size: 17px;
     font-weight: 600;
  }
  .port .Ports .dit .title{
    font-size: 17px;
    font-weight: 600;
  }
  .port .Ports .dit{
    width: 90%;
    height: 50px;
  }
  .port .Ports .btn {
    width: 90%;
  
  }
 
  .port .Ports img {
    border-radius: 20px;
    width: 95%;
    height: 350px;
  }
}
        