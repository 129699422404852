.loading-page {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    background-color: ghostwhite;
    
  }
  
  .loading-bar-container {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 50px;
    width: 100%;
  }
  
  .loading-bar {
    width: 10px;
    height: 40px;
    margin: 0 5px;
    background-color: #000;
    animation: loading-bar-animation 1.2s ease-in-out infinite;
  }
  
  .loading-bar:nth-child(1) {
    animation-delay: 0s;
  }
  
  .loading-bar:nth-child(2) {
    animation-delay: 0.1s;
  }
  
  .loading-bar:nth-child(3) {
    animation-delay: 0.2s;
  }
  
  .loading-bar:nth-child(4) {
    animation-delay: 0.3s;
  }
  
  .loading-bar:nth-child(5) {
    animation-delay: 0.4s;
  }
  
  .loading-bar:nth-child(6) {
    animation-delay: 0.5s;
  }
  
  @keyframes loading-bar-animation {
    0% {
      transform: scale(1);
    }
    50% {
      transform: scale(0.4);
    }
    100% {
      transform: scale(1);
    }
  }
  
  /* Responsive design using media queries */
  @media screen and (max-width: 768px) {
    .loading-bar-container {
      height: 40px;
    }
    .loading-bar {
      width: 8px;
      height: 30px;
      margin: 0 4px;
    }
  }
  
  @media screen and (max-width: 480px) {
    .loading-bar-container {
      height: 30px;
    }
    .loading-bar {
      width: 6px;
      height: 20px;
      margin: 0 3px;
    }
  }
  