
.containerz {
    max-width: 800px;
    margin: 0 auto;
    padding: 20px;
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}
.login{
    height: 70vh;
    
}
.login-title {
    text-align: center;
    color: #333;
   
}

.login-form {
    margin-top: 20px;
}

.login-label, .login-input {
    display: block;
    margin-bottom: 10px;
    width: 80%;
}

.login .login-input{
   
}
.login-input[type="text"], .login-input[type="password"] {
    width: 90%;
    padding: 10px;
    border: 1px solid #ccc;
    border-radius: 4px;
}

.login-button {
    display: block;
    margin-top: 10px;
    padding: 10px 20px;
    background-color: #07406e;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
}

.login-success, .login-failure {
    display: none;
    text-align: center;
    margin-top: 10px;
    padding: 10px;
    background-color: #4CAF50; /* Green for success */
    color: white;
    border-radius: 4px;
}

.login-failure {
    background-color: #f44336; /* Red for failure */
}

.sign-up-link {
    text-align: center;
    margin-top: 20px;
}

.sign-up-link a {
    color: #07406e;
    text-decoration: none;
    font-weight: bold;
}

.sign-up-link a:hover {
    text-decoration: underline;
}

.flash-messages{
    position: absolute;
    top: 10%;
}
/* Flash message container */
 /* .flash-messages {
    position: absolute;
    top: 10%;
    left: 0;
    width: 100%;
    text-align: center;
    background: #f44336;
    color: white;
    padding: 10px;
}

/* Flash message list */

